const setHeaderScroll = () => {
    const header = document.querySelector('[data-header]');

    window.addEventListener('scroll', () => {
        let scrollPosition = window.scrollY;

        if (scrollPosition >= 149) {
            header.classList.add('is-scroll');
        } else {
            header.classList.remove('is-scroll');
        }

    });
}