// https://github.com/kenwheeler/slick/

const slickSliderInit = () => {
    $("[data-slider-service]").slick({
        infinite: false,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $("[data-slider-reference]").slick({
        infinite: false,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    });
};
